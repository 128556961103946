import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import { Intro } from '../components/Intro/Intro';
import Connect from '../components/Connect';
import { StyledLink } from '../components/StyledLink';
import Projects from '../components/Projects';
import { Container, CSSGrid, Col } from '../components/Grid';
import Memoji from '../components/Memoji';
import { designTokens } from '../components/Theme/designTokens';
import { isLoggedIn } from '../utilities/services/auth';
import { Pill } from '../components/Pill';
import { Badge } from '../components/Badge';
import MailIcon from '../static/mail.svg';
import { motion } from "framer-motion";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Product Designer" keywords={['portfolio', 'Ryan Parag', 'designer', 'UI/UX', 'product designer']} mdxType="SEO" />
    <Container mdxType="Container">
  <CSSGrid mdxType="CSSGrid">
    <Col mdxType="Col">
      <Intro initial={{
            y: `-${designTokens.space[5]}`,
            opacity: 0
          }} animate={{
            y: 0,
            opacity: 1
          }} transition={{
            duration: 0.25,
            delay: 0.1
          }} mdxType="Intro">
        <Memoji mdxType="Memoji" />
        <h2>Hello, I'm Ryan!</h2>
        <p className="lead">I'm a Digital Product Designer currently based in Tampa, Florida. I help build digital products and solve tough problems — focusing on user research & testing, prototyping, visual design, front-end code, and product strategy. <StyledLink underline="true" to={'/about'} mdxType="StyledLink">More...</StyledLink></p>
        <motion.div initial={{
              opacity: 0,
              y: `-${designTokens.space[3]}`
            }} animate={{
              y: 0,
              opacity: 1
            }} transition={{
              duration: 0.25,
              delay: 0.2
            }}>
        {isLoggedIn() ? <>
              <Badge marginBottom={designTokens.space[2]} mdxType="Badge">
                <img src={MailIcon} width="32" style={{
                    marginRight: designTokens.space[2],
                    transform: 'rotate(10deg)'
                  }} />
                <strong>
                  I'm looking for a new role!
                </strong>
              </Badge>
              <p>
                <small>
                I'm open to relocate to SF, Seattle, Austin, New York, and more!{' '}
                <StyledLink underline="true" to={'/about/resume'} mdxType="StyledLink">View my resumé</StyledLink>
                </small>
              </p>
            </> : null}
        </motion.div>
        <Connect mdxType="Connect" />
      </Intro>
    </Col>
  </CSSGrid>
    </Container>
    <Projects mdxType="Projects" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      